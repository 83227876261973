.content {
    background-color: transparent;
    margin: 4px;
}

.leftMap {
    min-height: 50vh !important;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
}
