.divHeader {
    display: flex;
    align-items: center;

    .divDay {
        margin-right: auto;
    }
}

hr {
    color: lightgray;
    border-color: lightgray;
    background-color: lightgray;
    height: 1px;
    border-width: 0;
}

.firstWeekday {
    font-weight: bold;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
}

.firstWeekdate {
    font-weight: bold;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    color: gray;
}

.img_moon {
    max-width: 40px;
    width: 100%;
}

.sunrise_help {
    color: gray;
}
