.content {
    background-color: transparent;
    margin: 4px;
}

.drawerHandle {
    position: fixed;
    top: 60px;
    right: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 16px;
    text-align: center;
    background: #222d32;
    color: white;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    pointer-events: auto;
}

.categoryBtn {
    width: 100%;
}

.categoryGroup {
    width: 100%;
    padding: 0 0 20px 0 !important;
    text-align: center;
}

.firstWeekday {
    font-weight: bold;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
}

.firstWeekdate {
    font-weight: bold;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    color: gray;
}

.img_moon {
    max-width: 40px;
    width: 100%;
}

.img_sun {
    max-width: 60px;
    width: 100%;
}

.divDateSummary {
    display: flex;
    align-items: center;

    .divTemp {
        display: flex;
        align-items: center;

        .Number {
            font-family: Apple SD Gothic Neo, arial, sans-serif;
            font-size: xxx-large;
        }

    }

    .divDetail {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 4px;

        p {
            color: gray;
            margin: 0;
        }
    }

    .divDateLocation {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 4px;
        margin-left: auto;

        .location {
            font-family: Apple SD Gothic Neo, arial, sans-serif;
            font-weight: 400;
            margin: 0;
        }

        .date {
            color: gray;
            margin: 0;
        }
    }
}

.select {
    width: auto;
    min-width: 150px;
}