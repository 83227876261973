.profileItem {
    padding:32px 16px 0px 16px;
    .profileItemContent {
        // padding:16px;
        .profileText {
            font-weight: bold;
            line-height: 2rem;
        }
    }
}

.divButton {
    display: flex;
    justify-content: center;
}

.buttonLeft {
    margin-right: auto;
}