.mediaboxWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    .image {
        max-width: 1000px;
        width: 100%;
    }
    .actionBar {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .leftArrow, .rightArrow {
            cursor: pointer;
            padding: 16px;
            color: white;
            font-weight: bold;
            font-size: 18px;
            transition: 0.6s ease;
            user-select: none;
            &:hover {
                background-color: rgba(0, 0, 0, 0.8);
            }
        }
    }
    .cameraInfoText {
        position: absolute;
        bottom: -60px;
        height: 60px;
        width: 100%;
        background-color: black;
        color: white;
        text-align: center;
        .infoCol {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 40px;
                margin: 8px;
            }
        }
    }
}