.authWrapper {
    // height:100vh;
    // overflow: hidden;
}

.leftSide {
    display: flex;
    align-items: center;
    justify-content: center;

    .logoWrapper {
        position: absolute;
        text-align: center;

        img {
            width: 100px;
            height: 100px;
        }

        h1 {
            color: rgba(255, 130, 0, 1);
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
            font-family: 'Work Sans', sans-serif;
            font-weight: Bold;
            font-size: 48px;
            opacity: 1;
        }
    }
}

.authBg {
    width: 100%;
    height: 100vh;
}

.siginFormWrapper {
    width: 100%;
    flex: 1;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}


.rightpanel {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.signupFormHeader {
    display: flex;
    padding: 16px;
    background: #fff;

    span {
        margin-left: auto;
        margin-right: 8px;
        color: gray;
    }
}

.cardHeader {
    background-color: #dc6300;
    border: 0;
    color: white;
    text-align: center;
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    h3 {
        color: white;
        text-align: center;
        margin: 4px 0px;
        font-size: 1.75rem;
    }
}

.cardBody {
    border: 0;
    text-align: center;
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    h5 {
        color: gray;
        text-align: center;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        font-size: 1.25rem;
    }

    hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    p {
        margin: 4px;
    }

    button {
        width: 50%;
        color: #fff;
        background-color: #dc6300;
        border-color: #dc6300;
        margin: 8px auto;

        &:hover {
            color: #fff;
            background-color: #b65200;
            border-color: #a94c00;
        }
    }
}