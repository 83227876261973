.profileItem {
    padding:32px 32px 0px 32px;
    .profileItemContent {
        padding:16px;
        .profileText {
            font-weight: bold;
            line-height: 2rem;
        }
    }
}
.box {
    margin-bottom: 10px;
    display: flex;
}

.footer {
    display: flex;
    .right {
        margin-left: auto;
    }
}

.control {
    width: 100%;
    max-width: 400px;
    display: block;
    margin-bottom: 16px;
}
.divControl {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    input {
        width: 100%;
        max-width: 400px;

    }
    span {
        margin-left: 16px;
        // color: paleturquoise
    }
}
