.divLeft {
    flex: 1;
}
.divBody {
    display: flex;
}

.labelName {
    color: #DC6300 !important;
    font-size: medium;
}

.labelIMEI {
    color: gray !important;
}

.labelDate {
    color: gray !important;
}

.image {
    height: 36px;
    margin-right: 8px;
}

.divCarrier {
    display: inline-block;
    margin-right: 8px;
    .image {
        height: 12px;
        display: block;
        margin-right: 0px;
        margin-left: auto;
    }
    .labelType {
        margin-right: 0px;
        margin-left: auto;
        text-align: right;
        color: gray !important;
        margin-top: 4px;
    }
}