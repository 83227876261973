.content {
    background-color: transparent;
    margin: 4px;
}

.leftMap {
    min-height: 50vh !important;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
}

.containerFloat {
    position: fixed;
    bottom: 0px;
    right: 0px;
    button, a {
        margin: auto  16px  16px auto;
        z-index: 1000;
    }
}