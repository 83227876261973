.divBody {
    display: flex;
    align-items: center;
    .iconWrap {
        display: flex;
        align-items: center;
    }
    .iconWrap:first-child {
        width: 120px;
    }
    .groupItemIcon {
        font-size: 16px;
        margin-right: 6px;
    }
    .groupItemIcon:last-child {
        margin-right: 0px;
    }
}