.content {
    background-color: transparent;
    margin: 4px;
    input {
        width: 100%;
    }
}

.leftMap {
    min-height: 50vh !important;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
}


.containerFloat {
    position: absolute;
    height: calc(100vh - 64px);
    width: 100%;
    display: flex;
    a {
        margin: auto  32px  32px auto;
        z-index: 1000;
    }
}
