.authWrapper {
    // height:100vh;
    // overflow: hidden;
}
.leftSide {
    display: flex;
    align-items: center;
    justify-content: center;
    .logoWrapper {
        position: absolute;
        text-align: center;
        img {
            width: 100px;
            height: 100px;
        }
        h1 {
            color: rgba(255, 130, 0, 1);
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
            font-family: 'Work Sans', sans-serif;
            font-weight: Bold;
            font-size: 48px;
            opacity: 1;
        }
    }
}
.authBg {
    width: 100%;
    height: 100vh;
}
.siginFormWrapper {
    width: 100%;
    flex: 1;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}


.rightpanel {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.signupFormHeader {
    display: flex;
    padding: 16px;
    background: #fff;
    span {
        margin-left: auto;
        margin-right: 8px;
        color: gray;
    }
}

.bottomContact {
    position: absolute;
    width: 100%;
    height: 100vh;
    .divContact {
        position: absolute;
        bottom: 8px;
        right: 8px;
        p {
            margin: 0;
            color: gray;
        }
    }
}
