.limitInfoDiv {
    background-color: #f39c12;
    color: white;
    border-color: #e08e0b;
    border-radius: 3px;
    padding: 12px;
    margin: 8px;
    .title {
        font-size: 1.2rem;
        font-weight: bold;
        float: left;
    }
    .closeBtn {
        font-weight: bold;
        float: right;
    }
    .message {
        clear: both;
        padding-top: 3px;
    }
}