.divLeft {
    flex: 1;
}

.divBody {
    display: flex;
}

.labelIMEI {
    color: #DC6300 !important;
}



.imageContainer {
    display: flex;
    text-align: justify;
    padding-left: 24px;
    padding-right: 8px;

    .img_sun_first {
        width: 32px;
        height: 32px;
        display: inline-block;
    }
    .img_sun {
        width: 32px;
        height: 32px;
        margin-left: auto;
        display: inline-block;
    }
    
    p.second {
        margin-left: auto;
        display: inline-block;
    }
    
    p.first {
        display: inline-block;
    }
}
