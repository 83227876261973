.authWrapper {
    // height:100vh;
    // overflow: hidden;
}
.leftSide {
    display: flex;
    align-items: center;
    justify-content: center;
    .logoWrapper {
        position: absolute;
        text-align: center;
        img {
            width: 100px;
            height: 100px;
        }
        h1 {
            color: rgba(255, 130, 0, 1);
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
            font-family: "Work Sans", sans-serif;
            font-weight: Bold;
            font-size: 48px;
            opacity: 1;
        }
    }
}
.authBg {
    width: 100%;
    height: 100vh;
}
.siginFormWrapper {
    width: 100%;
    flex: 1;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    h4 {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 24px;
    }
    span {
        color: gray;
        margin-bottom: 16px;
    }
    h3 {
        font-weight: bold;
        margin-bottom: 8px;
    }
    button {
        width: 100%;
        color: #fff;
        background-color: #dc6300;
        border-color: #dc6300;
        margin-top: 24px;
        &:hover {
            color: #fff;
            background-color: #b65200;
            border-color: #a94c00;
        }

        span {
            color: white;
        }
    }
    
    input:focus {
        outline: none !important;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
    .divPin {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
    }
}

.signupFormHeader {
    display: flex;
    padding: 16px;
    background: #fff;
    span {
        margin-left: auto;
        margin-right: 8px;
        color: gray;
    }
}

.rightpanel {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
