.videoModoal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
}
.tagInput {
    display: flex;
}
.cardCover {
    .checkBox {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    position: relative;
    .image {
        width: 100%;
    }
    .img_antler {
        position: absolute;
        top: 0px;
        left: 10px;
        width: 40px;
    }
    .tag {
        position: absolute;
        bottom: 4px;
        right: 4px;
        margin: 0px;
        background: rgba($color: #fff, $alpha: 0.8);
    }
}
.videoCard {
    width: 100%;
    height: 100%;
}
.imageCategory {
    padding: 2px 5px;
    color: #aaa;
    border: solid 1px #dedede;
    display: flex;
    align-items: center;
    margin-right: 3px;
}

.imageCategory div {
    width: 15px;
    height: 15px;
    margin-right: 3px;
    border: solid 1px #dedede;
}

.labelCamera {
    font-family: 'Source Sans Pro',sans-serif;
    font-size: 12px;
}

.labelTime {
    color: gray;
    font-family: 'Source Sans Pro',sans-serif;
    font-size: 12px;
}