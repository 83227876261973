.content {
    background-color: transparent;
    margin: 4px;
}

.divider {
    display: flex;
    color: rgba(0,0,0,.85);
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    border-top: 0;
    border-top-color: #000;

    .dividerText {
        padding-right:1em;
        margin-top: -2px;
    }
    &::after{
        position: relative;
        top: 50%;
        width: 100%;
        border-top: 1px solid transparent;
        border-top-color: inherit;
        border-bottom: 0;
        transform: translateY(50%);
        content: "";
    }
}

.drawerHandle {
    position: fixed;
    top: 60px;
    right: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 16px;
    text-align: center;
    background: #222d32;
    color: white;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    pointer-events: auto;
}

.activityCard {
    margin-bottom: 4px !important;
}

.imageFilter {
    margin: 20px 0 0 15px;
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 68px;
}