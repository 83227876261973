.boxSmall {
    color: #fff;
    display: flex;
    align-items: center;
    margin: 8px;
    h3 {
        font-size: 28px;
        font-weight: bold;
        margin: 0 0 10px 0;
        white-space: nowrap;
        padding: 0;
        font-family: "Source Sans Pro", sans-serif;
        line-height: 1.1;
        color: inherit;
    }
    p {
        // font-weight: bold;
    }
    span {
        opacity: 0.7;
        margin: 0;
        font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    img {
        height: 50px;
        opacity: 0.5;
        position: absolute;
        right: 8px;
    }

}

.card {
    padding: 0px !important;
    a {
        position: relative;
        text-align: center;
        padding: 3px 0;
        color: #fff;
        color: rgba(255, 255, 255, 0.8);
        display: block;
        z-index: 10;
        background: rgba(0, 0, 0, 0.1);
        text-decoration: none;
    }
}
