.formWrapper {
    h4 {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 24px;
    }
    .formItemLabel {
        font-weight: bold;
        margin-bottom: 8px;
    }
    .btnSignin {
        width: 100%;
        color: #fff;
        background-color: #dc6300;
        border-color: #dc6300;
        margin-top: 24px;
        &:hover {
            color: #fff;
            background-color: #b65200;
            border-color: #a94c00;
        }
    }
}