.content {
    background-color: transparent;
    margin: 4px;
}

.containerFloat {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    button {
        display: block;
        z-index: 900;
        width: 120px;
        margin-bottom: 4px;
        text-align: center;
    }
    .divRight {
        position: fixed;
        bottom: 10px;
        right: 10px;
    }
    .divLeft {
        left: 10px; 
        bottom: 10px;
    }
}