.content {
    background-color: transparent;
    margin: 10px;
}

.divider {
    display: flex;
    color: rgba(0,0,0,.85);
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    border-top: 0;
    border-top-color: #000;

    .dividerText {
        padding-right:1em;
        margin-top: -2px;
    }
    &::after{
        position: relative;
        top: 50%;
        width: 100%;
        border-top: 1px solid transparent;
        border-top-color: inherit;
        border-bottom: 0;
        transform: translateY(50%);
        content: "";
    }
}