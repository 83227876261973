.ant-select-selector{
    /* background-color: transparent !important; */
    /* border: none !important; */
    /* border-bottom: 1px solid #9e9e9e !important; */
    outline: 0 !important;
    box-shadow: none !important;
    border-radius: 0px !important;
}

/* .ant-select-focused .ant-select-selector{
    border-bottom: 1px solid #677367 !important;
}
.ant-menu-item *{
    color: #DC6300 !important;
    font-weight: bold;
}
.ant-menu-item:hover {
    background-color: #46270e !important;
}
.ant-menu-item-selected {
    background-color: #fff !important;
}

.ant-input, .ant-input-affix-wrapper {
    border: 1px solid #dc6300;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #dc6300;
}
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 0.2rem rgb(220 99 0 / 25%);
}
.ant-input-affix-wrapper:hover {
    border: 1px solid #dc6300;
}
.ant-input:hover {
    border: 1px solid #dc6300;
}
.ant-input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ffa65d;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(220 99 0 / 25%);
} */

.image-gallery-modal .ant-modal-content{
    background-color: transparent;
    box-shadow: none;
}

.ant-input-number, .ant-input-number-affix-wrapper {
    /* border: 1px solid #dc6300; */
    width: 100%;
}

/* pro layout side menu */
.ant-menu {
    background: #222d32 !important;
}

.ant-pro-sider-logo {
    background-color: #222d32;
}
/* pro layout page container header */
.ant-pro-page-container-warp {
    display: none;
}
.ant-page-header-ghost {
    display: none;
}
.ant-pro-page-container-children-content {
    margin: 0 !important;
}

body {
    background-color: #f0f2f5 !important;
}
@primary-color: #dc6300;@link-color: #dc6300;