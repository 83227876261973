.content {
    background-color: transparent;
    margin: 4px;
    input {
        width: 100%;
    }
}

.leftMap {
    margin-left: -50px;
    width: calc(25% + 50px) !important;
    flex-basis: auto !important;
    max-width: calc(25% + 50px) !important;
}

.containerFloat {
    position: absolute;
    height: calc(100vh - 64px);
    width: 100%;
    display: flex;
    a {
        margin: auto 32px 32px auto;
        z-index: 1000;
    }
}

.control {
    width: 100%;
}

.divTimeLapse {
    display: flex;
    align-items: center;
    button {
        margin: 4px;
    }
}

.textMuted {
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
}

.divCamera {
    display: flex;
    align-items: center;
    .select {
        width: auto;
        min-width: 150px;
    }
    margin-bottom: 8px;
}


.divFooter {
    button {
        float: right;
    }
}