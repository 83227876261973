.profileItem {
    .profileItemContent {
        padding: 16px;

        .profileText {
            font-weight: bold;
            line-height: 2rem;
        }
    }
}

.rowSuspended {
    background-color: lightgray;
}