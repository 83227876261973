.content {
    background-color: transparent;
    .mapTitle {
        display: flex;
        background-color: white;
        height: 40px;
        align-items: center;
        h3 {
            width: 100%;
            text-align: center;
            margin-bottom: 0px !important;
        }
    }
    .image {
        object-fit: contain;
        width: 40px;
        height: 40px;
    }
    .cameraDiv {
        border: 1px solid lightgray;
        border-radius: 5px;
        text-align: center;
        padding: 5px;
        margin: 0px 5px;
        margin-bottom: 8px;
    }
    .addGroup {
        font-style: italic;
        cursor: pointer;
        display: inline;
    }
    .leftMap {
        min-height: 50vh !important;
        padding-top: 12px;
        padding-left: 12px;
        padding-right: 12px;
    }
    .optionClear {
        background-color: lightgray;
    }
}