.tagContainer {
    
}
.videoModoal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
}
.tagInput {
    display: flex;
}
.cardCover {
    .checkBox {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 700;
    }
    position: relative;
    img {
        width: 100%;
    }
}
.videoCard {
    width: 100%;
    height: 100%;
}