.divLeft {
    flex: 1;
}

.divBody {
    display: flex;
}

.labelIMEI {
    color: #DC6300 !important;
}

.selectMonth {
    width: 100%;
}