.wrapper {
    background-color: white;
    padding: 8px;
    .formInputLabel{
        margin-bottom: 4px;
    }
    .formInputWrapper {
        display: flex;
        align-items: center;
        .checkBox {
            // width: 100px;
            // height: 100px;
        }
    }
    .divider {
        display: flex;
        color: rgba(0,0,0,.85);
        font-size: 16px;
        white-space: nowrap;
        text-align: center;
        border-top: 0;
        border-top-color: #000;

        .dividerText {
            padding-right:1em;
            margin-top: -2px;
        }
        &::after{
            position: relative;
            top: 50%;
            width: 100%;
            border-top: 1px solid transparent;
            border-top-color: inherit;
            border-bottom: 0;
            transform: translateY(50%);
            content: "";
        }
    }
}
.accordion_header {
    margin-left: -12px;
}
.accordian_panel{
    border: none !important;
    .categoryItem{
        font-size: 16px;
        color: #8e95a0 !important;
        cursor: pointer;
        transition: color 0.6s cubic-bezier(0.55, 0, 0.1, 1);
        margin-bottom: 4px;
        &:hover{
            color: rgb(40, 60, 86) !important;
        }
        &.selected{
            color: rgb(40, 60, 86) !important;
        }
    }
}
.selected_filter_Option{
    padding: 2px 10px;
    border-radius: 50px;
    border: solid 1px rgb(40, 60, 86);
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.editSaveBtn {
    width: 100%;
}