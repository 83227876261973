.content {
    background-color: transparent;
    margin: 8px;
}

.drawerHandle {
    position: fixed;
    top: 60px;
    right: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 16px;
    text-align: center;
    background: #222d32;
    color: white;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    pointer-events: auto;
}

.imageFilter {
    margin:8px 0 0 8px;
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
}

.categoryBtn {
    width: 100%;
}
.categoryGroup {
    width: 100%;
    padding: 0 0 20px 0 !important;
    text-align: center;
}